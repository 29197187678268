exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-docs-tsx": () => import("./../../../src/pages/docs.tsx" /* webpackChunkName: "component---src-pages-docs-tsx" */),
  "component---src-pages-heirachy-tsx": () => import("./../../../src/pages/heirachy.tsx" /* webpackChunkName: "component---src-pages-heirachy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-input-tsx": () => import("./../../../src/pages/input.tsx" /* webpackChunkName: "component---src-pages-input-tsx" */),
  "component---src-pages-names-tsx": () => import("./../../../src/pages/names.tsx" /* webpackChunkName: "component---src-pages-names-tsx" */),
  "component---src-pages-one-tsx": () => import("./../../../src/pages/one.tsx" /* webpackChunkName: "component---src-pages-one-tsx" */),
  "component---src-pages-sync-2-tsx": () => import("./../../../src/pages/sync2.tsx" /* webpackChunkName: "component---src-pages-sync-2-tsx" */),
  "component---src-pages-sync-tsx": () => import("./../../../src/pages/sync.tsx" /* webpackChunkName: "component---src-pages-sync-tsx" */)
}

